import React, { SyntheticEvent } from "react"
import { Color, getBgColorStyle, getColorStyle } from "../utils/color"
import { navigate } from "gatsby"
import "./newsletter.css"

interface Props {
  tag: string
  value: string
  label: string
  labelColor?: Color | string
  labelBgColor?: Color | string
}

const Newsletter: React.FC<Props> = ({
  tag,
  value,
  label = "Subscribe",
  labelColor = "turkis-blue1",
  labelBgColor = "white",
}) => {
  /**
   * Event handler for a form submit event.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/submit_event
   *
   * @param {SubmitEvent} event
   */
  async function handleFormSubmit(event) {
    event.preventDefault()

    const form = event.currentTarget

    try {
      const formData = new FormData(form)
      let name = ""
      let email = ""
      for (var pair of formData.entries()) {
        if (pair[0] === "EMAIL") {
          email = pair[1].toString()
          localStorage.setItem("S_U_EMAIL", pair[1].toString())
        }
        if (pair[0] === "FNAME") {
          name = pair[1].toString()
          localStorage.setItem("S_U_NAME", pair[1].toString())
        }
        // console.log(pair[0], pair[1])
      }
      if (value === "survey") {
        navigate("/survey")
      } else {
        // console.log(url)
        // const responseData = await postFormDataAsJson({ url, formData })
        // console.log({ responseData })

        const sendDataToMailchimp = payload => {
          const url = `/ivone-sub.php?name=${payload.name}&email=${payload.email}&tag=${payload.tag}&value=${payload.value}`
          postData(url)
        }
        // Example POST method implementation:
        const postData = async (url = "") => {
          // Default options are marked with *
          const response = await fetch(url, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "no-cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
              // Accept: "application/json",
              "Content-Type": "application/json",
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          })
          return response
        }

        sendDataToMailchimp({
          name,
          email,
          tag,
          value,
        })

        localStorage.clear()
        navigate("/pasos-nuevo-suscriptor")
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div id="mc_embed_signup">
      <form
        action="https://ivone-herrera.us4.list-manage.com/subscribe/post?u=45cfa4442de838dfbec7f0d69&amp;id=646bc69f02"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        onSubmit={handleFormSubmit}
      >
        <div id="mc_embed_signup_scroll" className="newsletter-form-content">
          <div className="mc-field-group email-name">
            <input
              type="email"
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
              placeholder="Email"
              required
            />

            <input
              type="text"
              name="FNAME"
              className=""
              id="mce-FNAME"
              placeholder="Nombre"
              required
            />
            <input
              type="submit"
              value={label}
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button"
              style={{
                ...getBgColorStyle(labelBgColor),
                ...getColorStyle(labelColor),
              }}
            />
          </div>
          <div
            id="mergeRow-gdpr"
            className="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group"
          >
            <div className="content__gdpr">
              <fieldset
                className="mc_fieldset gdprRequired mc-field-group"
                name="interestgroup_field"
              >
                <label className="checkbox subfield">
                  <input
                    type="checkbox"
                    // id="gdpr_43718"
                    name="gdpr[43718]"
                    className="av-checkbox gdpr"
                    required
                  />
                  <span>
                    GDPR Política de privacidad. Puedes retirarte de la lista de
                    suscripción en cualquier momento
                  </span>
                </label>
              </fieldset>
            </div>
          </div>
          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_45cfa4442de838dfbec7f0d69_646bc69f02"
              tabIndex={-1}
            />
          </div>
          <input
            type="hidden"
            value={value}
            name={tag}
            className=""
            id={`mce-${tag}`}
            readOnly
          />
        </div>
      </form>
    </div>
  )
}

export default Newsletter
