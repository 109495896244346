//components
//components
import React from "react"
import { Grid } from "@material-ui/core"
import BulletItem from "../components/bullet-item"
import Newsletter from "../components/newsletter"
import Article from "../components/article"
import Carousel from "../components/carousel"
import Section from "../components/section"
import HeroSection from "../components/hero-section"
import Testimonial from "../components/testimonial"
import ListCard from "../components/list-card"
import AboutImage from "./assets/Ivone-Foto-4.jpg"
import About from "../components/about"
import Card from "../components/card"

//IMAGES
import HeroImage from "./assets/Ivone-Foto5-flipped.jpg"
import ImageEstrategia from "./assets/pexels-pixabay-277124-2.jpg"
import ImageSensibilidad from "./assets/pexels-nataliya-vaitkevich-5201527-2.jpg"
import ImageAccion from "./assets/pexels-andre-furtado-5180962.jpg"
import ImageEquipo from "./assets/pexels-andrea-piacquadio-3830752.jpg"
import Navigation from "../components/navigation"
import ImageSesiones from "./assets/Sesiones-pexels-mentatdgt-1311518.jpg"
import ImageConfianza from "./assets/CONFIDENCE-pexels-moose-photos-1036622.jpg"
import ImageConfidenceRev from "./assets/ConfidenceRevolution.jpg"
import ImageTurkisWay from "./assets/jailam-rashad-hSFgNXOztLU-unsplash.jpg"
// import ImageDeepocean from "./assets/pexels-emiliano-arano-1350197.jpg"

//ICONS
import { IoIosArrowForward } from "react-icons/io"
import Footer from "../components/footer"
import { ImCircleRight } from "react-icons/im"

export default function SM() {
  const bulletIcon1 = <IoIosArrowForward color="#269fb2" size="36px" />
  const bulletIcon4 = <ImCircleRight color="#269fb2" size="26px" />

  const Testimonio_8 = `Al grano: te recomiendo a Ivone totalmente. 
  Si hubiera encontrado este programa antes me hubiera ahorrado mucha frustración 
  y mucho sufrimiento y hubiera logrado, todo lo que he logrado desde que empece este programa, 
  hace mucho tiempo. Lo que más me gusto es el enfoque de estrategia y acción, porque pude ver la diferencia en mi vida 
  inmediatamente. No lo pienses más, ¡Apúntate! `
  const TestName_8 = "Laura Adán"

  const Testimonio_9 = `¡Transformación total! El coaching con Ivone marcó un antes y un después en mi vida!. 
    Hoy me siento confiada de mi misma como nunca antes lo había sentido, y sé que puedo lograr todo lo que me propongo.`
  const TestName_9 = "Eva López"

  const Testimonio_10 = `Gracias al coaching con Ivone, logré renunciar al trabajo con el que ya tenía años insatisfecha. 
    En mi nuevo trabajo de verdad siento que estoy aprovechando toda mi experiencia y me siento mucho más felíz. 
    Además todas mis amigas notaron el cambio que dí!.  Ahora me siento capaz de lograr muchas cosas que 
    antes veía imposibles. ¡Gracias Ivone!`
  const TestName_10 = "Jazmín."

  const List_MisEventosEspanol = [
    <span>
      {" "}
      Taller
      <strong>
        {" "}
        "Emociones: el Motor para tu Desarrollo Personal y Profesional en
        Europa."{" "}
      </strong>
      Para el 5to Encuentro de Mujeres Hispano Hablantes en Europa:
      <strong>
        {" "}
        “Desarrollo profesional como mujer migrante en Europa: Reintégrate,
        Renuévate, Reinvéntate”
      </strong>
      , Organizado por el equipo de Mujeres Hispanohablantes en Europa (MHE) con
      la colaboración de la Secretaría de Relaciones Exteriores de México, el
      Instituto de los Mexicanos en el Exterior y la Red global de Mexicanos en
      el extranjero en la <strong>Embajada de México en Berlin</strong>, Octubre
      2022.
    </span>,

    <span>
      {" "}
      Participación en el Panel<strong> "Mujeres que Inspiran" </strong>
      por mi destacada y exitosa trayectoria como mujer migrante en Europa. Para
      el 5to Encuentro de Mujeres Hispano Hablantes en Europa. 'Reintégrate,
      Renuévate, Reinvéntate', en la{" "}
      <strong>Embajada de México en Berlin</strong>, Octubre 2022.
    </span>,

    <span>
      {" "}
      Masterclass
      <strong> "Cómo motivarte para Lograr tus Metas" </strong>
      para el Espacio Internacional de Psicología, Abril 2022.
    </span>,

    <span>
      {" "}
      Conferencia Virtual
      <strong>
        {" "}
        “La Triada de tu Éxito como Mujer: Reconocimiento, Autoestima y
        Confianza”{" "}
      </strong>
      como parte del Congreso Virtual “Mujer por Fuera y por Dentro”, Mayo 2021.
    </span>,

    <span>
      {" "}
      Conferencia Virtual
      <strong> "Entiendo mis Emociones y Atiendo mis Necesidades" </strong>
      para la Organización de Mujeres Hispanohablantes en Alemania, Febrero
      2021.
    </span>,

    <span>
      {" "}
      Conferencia Virtual
      <strong>
        {" "}
        "Cómo Ganarle a la Impostora que Vive en Ti. Diez Estrategias para
        Desarrollarte Personal y Profesionalmente en el Extranjero"{" "}
      </strong>
      como parte del evento virtual Bienestar Emocional y Holístico para la
      Comunidad de Latinas en Berlin, Alemania, Diciembre 2020.
    </span>,

    <span>
      {" "}
      Artículo{" "}
      <strong>
        {" "}
        "Las Palabras que Usamos Impactan Emocionalmente Nuestras Vidas"{" "}
      </strong>
      para la Revista Digital Hispanas Creativas, Diciembre 2020.
    </span>,

    <span>
      {" "}
      Episodio de Podcast
      <strong>
        {" "}
        "Síndrome del Impostor: ¿Porque nos afecta más a los migrantes?"{" "}
      </strong>
      para el Podcast Onda Sana de la Comunidad de Hispanos Migrantes, Diciembre
      2020.
    </span>,
  ]

  const List_MisEventosIngles = [
    <span>
      {" "}
      Workshop
      <strong> "Embrace your value and boost your Confidence" </strong>
      con el grupo de Chicas Superpoderosas Let's Change Our Inner World
      Together en meetup, Octubre 2020.
    </span>,

    <span>
      {" "}
      Workshop
      <strong> "How to boost your Self-Esteem and Self-Confidence" </strong>
      con el grupo de Chicas Superpoderosas Let's Change Our Inner World
      Together en meetup, Octubre 2020.
    </span>,

    <span>
      {" "}
      Workshop
      <strong> "Confident ME- Women Only" </strong>
      con el grupo de Chicas Superpoderosas Let's Change Our Inner World
      Together en meetup, Noviembre 2019.
    </span>,

    <span>
      {" "}
      Workshop
      <strong>
        {" "}
        "Deep conversations about Vulnerability: finding new insights"{" "}
      </strong>
      con el grupo de Chicas Superpoderosas Let's Change Our Inner World
      Together en meetup, Julio 2019.
    </span>,

    <span>
      {" "}
      Workshop
      <strong>
        {" "}
        "How to Overcome Self-Doubt and become inspired using regular people as
        Role Models"{" "}
      </strong>
      con el grupo de Chicas Superpoderosas Let's Change Our Inner World
      Together en meetup, Octubre 2018.
    </span>,

    <span>
      {" "}
      Workshop
      <strong>
        {" "}
        "How to stop your Limiting Beliefs and stop them from affecting your
        future"{" "}
      </strong>
      con el grupo de Chicas Superpoderosas Let's Change Our Inner World
      Together en meetup, Septiembre 2018.
    </span>,

    <span>
      {" "}
      Workshop
      <strong> "Transforming your Dream Life in to Smart Goals" </strong>
      con el grupo de Chicas Superpoderosas Let's Change Our Inner World
      Together en meetup, Agosto 2018.
    </span>,

    <span>
      {" "}
      Workshop
      <strong> "Let's strengthen our Self-awareness" </strong>
      con el grupo de Chicas Superpoderosas Lets Change Our Inner World Together
      en meetup, Agosto 2018.
    </span>,
  ]

  return (
    <>
      <Navigation active={1} />

      <HeroSection
        bgImage={HeroImage}
        overlay="part"
        overlayClassName="white"
        titleClassName="left"
        color="turkis-blue1"
        // subtitle=" Ivone Herrera es Ingeniera Física con una Maestría en Simulaciones Computacionales por la Universidad Técnica de Berlin y el Instituto de Tecnología de Estocolmo.
        // Es mexicana y vive desde hace 10 años en Berlin donde decidió reinventarse profesionalmente."

        h1="Ivone Herrera es Coach de Vida, Especialista en Desarrollo Humano por el Instituto Humanista de Psicoterapia Gestalt, 
        en donde actualmente cursa un Master en Psicoterapia."
        subtitle="Ivone cuenta con más de 10 años de experiencia formando y capacitando personal. 
        Desde 2018 trabaja como profesional independiente facilitando cursos, talleres y conferencias para 
        expats y migrantes apoyándolos a que reconozcan sus talentos y experiencia para utilizarlos para construir la vida que quieren en su nuevo país.
        En sus talleres y sesiones personalizadas Ivone combina las herramientas prácticas del Coaching con la dimensión emocional de la Psicoterapia."
        forceAspectRatioOnMobile
      ></HeroSection>

      <Section color="blue1" id="Center-text" small>
        <Article className="white center lineheight-large">
          <span className="font-size-medium">
            Descarga mi Guía{" "}
            <span className="bold">
              "Cómo descubrir tu propósito y conectar con tu confianza"
              <br />
            </span>
            <span>
              {" "}
              y además entérate de los cursos y talleres en los que puedes
              participar.
            </span>
          </span>
          <Newsletter tag="GIVEAWAY" value="RECONECTA" label="Quiero la guía" />
        </Article>
      </Section>

      <Section color="white" id="Centered-text">
        <Article className="center">
          <span className="tenor  bold blue1 center font-size-verylarge  lineheight-extralarge">
            Eventos en los que he participado como Ponente
          </span>
          <ListCard
            text={List_MisEventosEspanol}
            listItemIcon={bulletIcon4}
          ></ListCard>
        </Article>
      </Section>

      <Section color="turkis-blue1" id="Center-text" bgImage={ImageTurkisWay}>
        <Article className="center  bold tenor white font-size-large lineheight-verylarge">
          ¿Sientes dentro de ti esa
          <br />
          necesidad urgente de hacer algo para transformar tu situación,
          <br /> y poder fluir más fácilmente con la vida?
        </Article>
      </Section>

      <Section color="blue">
        <Article
          layout="narrow"
          titleClassName=" tenor  white bold font-size-extralarge lineheight-verylarge"
          title="Eventos en Inglés"
        >
          <ListCard
            text={List_MisEventosIngles}
            listItemIcon={bulletIcon4}
          ></ListCard>
        </Article>
      </Section>

      <Section
        color="blue1"
        // bgImage={ImageDeepocean}
        // overlay="full"
        // overlayColor="rgba(250, 246, 246,.6)"
        // overlayColor="rgba(38, 159, 178, .6)"
      >
        <Article
          // className="playfair font-size-verylarge"
          // color="turkis-blue1"
          // bgImage={ImageTurkisWay}
          // // overlay="full"
          // overlayColor="rgba(38, 159, 178, 0)"

          layout="narrow"
          // titleClassName="tenor bold turkis-blue1 font-size-verylarge lineheight-large"
          titleClassName=" tenor white bold font-size-extralarge lineheight-verylarge"
          title="Mi Experiencia y Formación"
        >
          {/* <Grid container spacing={1}> */}
          <Grid item md={12}>
            <Card>
              {/* <Card icon={icon} title="Title1" className="center"></Card> */}
              <BulletItem icon={bulletIcon4}>
                <strong>Te cuesta trabajo reconocer tus talentos</strong> e
                incluso los haces menos.
              </BulletItem>
              <BulletItem icon={bulletIcon4}>
                No sabes cómo comunicar tus habilidades ni tu experiencia porque{" "}
                <strong>tú misma no estás convencidas de ellas</strong>.
              </BulletItem>
              <BulletItem icon={bulletIcon4}>
                <strong>Dudas de ti misma,</strong> de tus talentos y
                capacidades y <strong>de lo que eres capaz de lograr.</strong>
              </BulletItem>
              <BulletItem icon={bulletIcon4}>
                <strong>Te falta confianza en ti misma </strong> y eso se
                refleja en lo profesional, en tu economía y en la calidad de tus
                relaciones.
              </BulletItem>
              <BulletItem icon={bulletIcon4}>
                <strong>Quieres aprender a escuchar y confiar</strong> en lo que
                tú sientes, piensas y necesitas para tu vida.
              </BulletItem>
              <BulletItem icon={bulletIcon4}>
                Te falta una estrategia, que logre{" "}
                <strong> combinar inteligencia, emoción y acción,</strong> para
                transformar tu vida y empezar a ver resultados tangibles.
              </BulletItem>
            </Card>
            {/* </Grid> */}
          </Grid>
          {/* <ListCard text={List_Incluye} listItemIcon={bulletIcon4}> */}
        </Article>
      </Section>

      <Section color="white">
        <Article
          layout="wide"
          titleClassName="bold turkis-blue1 font-size-verylarge lineheight-large"
          title="Entiendo cómo te sientes porque yo también he estado ahí"
        >
          <Grid container spacing={1}>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
                Te sientes insatisfecha con la vida que llevas, no las disfrutas
                en plenitud porque no estas haciendo lo que te gustaría.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Sientes que no estás utilizando tus capacidades y talentos al
                100%.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Sabes que puedes más, porque ya lo has demostrado antes, pero en
                este momento te sientes atorada, te falta claridad y no sabes
                por dónde empezar para cambiar esta situación.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Quizá tienes un trabajo que, aunque estable, ni te gusta ni te
                llena personal ni profesionalmente y que además, te deja super
                agotada.
              </BulletItem>
            </Grid>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
                Tal vez quieres empezar con tu propio negocio, pero tienes
                muchas dudas y miedos. Muchas veces piensas que “todavía no
                estas lista” o que “todavía no sabes lo suficiente” y todo eso
                te tiene paralizada.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Te falta motivación, te sientes insegura y dudas de ti misma o
                de lo que podrías lograr.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Has estado largo tiempo en esta situación y eso a veces te
                provoca ansiedad.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Dependes económicamente y/o emocionalmente de tu pareja y eso
                afecta fuertemente tu autoestima.
              </BulletItem>
            </Grid>
          </Grid>
        </Article>
      </Section>

      <Section
        color="beige"
        //bgImage={ImageOceanBlue}
        // overlay="full"
        // overlayColor="rgba(98, 128, 174,.2)"
      >
        <Article className="center">
          <span className="tenor  bold blue1 center font-size-verylarge  lineheight-extralarge">
            ¿Quieres saber cómo te puedo ayudar?
          </span>
          <br /> <br />
          <span className="center turkis-blue1 font-size-medium">
            {" "}
            Mi método combina{" "}
          </span>
          <span className="bold turkis-blue1 font-size-medium">
            {" "}
            sensibilidad, estrategia y acción.{" "}
          </span>
          <br /> <br />
          <br /> <br />
        </Article>
        <Article>
          <Grid container spacing={3} justify="center">
            <Grid item md={3} sm={6} xs={10}>
              {/*  <Card icon={icon} title="Sensibilidad" className="center">
              Aprende a interpretar tus emociones para que sepas lo que necesitas hacer en cada momento.
              </Card> */}
              <Card
                avatar={ImageSensibilidad}
                title="Sensibilidad"
                titleClassName="bold  blue1 tenor font-size-medium"
                className="center turkis-blue1"
              >
                Aprende a interpretar tus emociones para que sepas lo que
                necesitas hacer en cada momento.
              </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={10}>
              {/* <Card icon={<FaChess size="36px" />}title="Estrategia" className="center">
              Aprende a reajustar tus acciones conscientemente para que te lleven más cerca de tus metas.
              </Card> */}
              <Card
                avatar={ImageEstrategia}
                title="Estrategia"
                titleClassName="bold  blue1 tenor font-size-medium"
                className="center turkis-blue1"
              >
                Aprende a reajustar tus acciones conscientemente para que te
                lleven más cerca de tus metas.
              </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={10}>
              {/* <Card icon={icon} title="Acción" className="center"> */}
              <Card
                avatar={ImageAccion}
                title="Acción"
                titleClassName="bold  blue1 tenor font-size-medium"
                className="center turkis-blue1"
              >
                Toma acción con intención y empieza a ver y disfrutar de
                resultados tangibles rápidamente.
              </Card>
            </Grid>
            <Grid item md={3} sm={6} xs={10}>
              {/* <Card icon={icon} title="Somos un equipo" className="center"> */}
              <Card
                avatar={ImageEquipo}
                title="Somos un equipo"
                titleClassName="bold  blue1 tenor font-size-medium"
                className="center turkis-blue1"
              >
                Soy tu confidente y la voz de apoyo que necesitas para surfear
                los desafíos que se te presentan.
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>

      <Section color="white" id="Centered-text">
        <Article className=" center font-size-medium blue1 lineheight-large">
          Frecuentemente me encuentro con mujeres muy talentosas,
          <br /> con mucha experiencia profesional y con mucha capacidad, como
          tú, pero
          <br />{" "}
          <span className="bold">
            {" "}
            se encuentran subvaloradas y generan ingresos muy por debajo de su
            capacidad.
          </span>
        </Article>
      </Section>

      <Section
        color="blue1"
        // bgImage={ImageDeepocean}
        // overlay="full"
        // overlayColor="rgba(250, 246, 246,.6)"
        // overlayColor="rgba(38, 159, 178, .6)"
      >
        <Article
          // className="playfair font-size-verylarge"
          // color="turkis-blue1"
          // bgImage={ImageTurkisWay}
          // // overlay="full"
          // overlayColor="rgba(38, 159, 178, 0)"

          layout="narrow"
          // titleClassName="tenor bold turkis-blue1 font-size-verylarge lineheight-large"
          titleClassName=" tenor white bold font-size-extralarge lineheight-verylarge"
          title="Seguramente te ha pasado que…"
        >
          {/* <Grid container spacing={1}> */}
          <Grid item md={12}>
            <Card>
              {/* <Card icon={icon} title="Title1" className="center"></Card> */}
              <BulletItem icon={bulletIcon4}>
                <strong>Te cuesta trabajo reconocer tus talentos</strong> e
                incluso los haces menos.
              </BulletItem>
              <BulletItem icon={bulletIcon4}>
                No sabes cómo comunicar tus habilidades ni tu experiencia porque{" "}
                <strong>tú misma no estás convencidas de ellas</strong>.
              </BulletItem>
              <BulletItem icon={bulletIcon4}>
                <strong>Dudas de ti misma,</strong> de tus talentos y
                capacidades y <strong>de lo que eres capaz de lograr.</strong>
              </BulletItem>
              <BulletItem icon={bulletIcon4}>
                <strong>Te falta confianza en ti misma </strong> y eso se
                refleja en lo profesional, en tu economía y en la calidad de tus
                relaciones.
              </BulletItem>
              <BulletItem icon={bulletIcon4}>
                <strong>Quieres aprender a escuchar y confiar</strong> en lo que
                tú sientes, piensas y necesitas para tu vida.
              </BulletItem>
              <BulletItem icon={bulletIcon4}>
                Te falta una estrategia, que logre{" "}
                <strong> combinar inteligencia, emoción y acción,</strong> para
                transformar tu vida y empezar a ver resultados tangibles.
              </BulletItem>
            </Card>
            {/* </Grid> */}
          </Grid>
          {/* <ListCard text={List_Incluye} listItemIcon={bulletIcon4}> */}
        </Article>
      </Section>

      <Section color="white" id="Centered-text">
        <Article className="center font-size-medium">
          La buena noticia es que todo esto tiene solución
          <br />
        </Article>
        <Article className=" playfair bold center font-size-large turkis-blue1 lineheight-large">
          <br />
          La confianza y la seguridad en ti misma pueden desarrollarse y
          <br />
          los resultados de la transformación son impactantes.
          <br />
          <br />
        </Article>
        <Article className=" bold center font-size-large blue1 lineheight-large">
          <span className="bold">
            Dentro de ti ya tienes todo lo que necesitas para triunfar.
          </span>
          <br />
          <br />
        </Article>
        <Article className="center font-size-medium lineheight-large">
          Lo único que requieres es
          <br />
          entender tus emociones, reajustar tu creencias y empezar a actuar
          <br />
          para dar rienda suelta y utilizar todo tu potencial.
        </Article>
      </Section>

      <Section color="beige1">
        <Article layout="wide">
          <About
            titleClassName="tenor bold center blue1 font-size-verylarge  lineheight-large"
            title="Te cuento un poco de mi historia"
            image={AboutImage}
          >
            <span className=" blue1 lineheight-large  ">
              <strong></strong>
              En 2012 perdí todo, sufrí <strong> depresión clínica</strong> y
              estuve al borde del <strong> suicidio</strong>. Sobreviví, pero
              durante años seguí sintiendome{" "}
              <strong> insatisfecha con mi vida, </strong>
              <strong> estancada, </strong> con{" "}
              <strong> ansiedad constante </strong> y con muchas{" "}
              <strong> dudas e incertidumbre. </strong>
              <br />
              <br />
              Mi intuición me decía que estaba en mis manos hacer algo radical
              para transformar mi mentalidad, mi situación y generar ingresos.
              <br />
              <br />
              <strong>
                Entonces recordé que años antes, yo era una mujer segura y
                confiaba de tal manera en mí misma, que era capaz de conseguirlo
                todo.
              </strong>{" "}
              No conocía imposibles ni excusas y podía materializar todo lo que
              yo quisiera.
              <strong>
                <br />
                <div className="center">
                  Estaba segura de que, si una fue así, podía volver a serlo.
                </div>
              </strong>
              <br />
            </span>
            <div className="bold center turkis-blue1  font-size-medium lineheight-large">
              ¿Qué elementos hacían la diferencia entre la sensación de poderlo
              todo y la desesperación total?
            </div>

            <div className="bold center blue1  font-size-medium lineheight-large">
              ¿Cuál era el secreto?
            </div>
            <br />
            <span className=" center blue1 lineheight-large ">
              Después de reconstruirme nuevamente en una mujer poderosa y
              confiada de mi misma,{" "}
              <strong>te lo puedo decir con certeza.</strong>
              <br />
              <br />
              <strong>
                {" "}
                Por todo esto decidí formarme como coach y mentora para poder
                enseñarlo a más mujeres y para que puedan aplicarlo a su vida.
              </strong>
              <br />
              Y precisamente por eso me estas leyendo ahora.
              <br />
              <br />
              <span className="bold left turkis-blue1 font-size-medium lineheight-large">
                Gracias a todo lo que he vivido y aprendido en este camino,
                estoy preparada y lista para compartirlo contigo.
              </span>
            </span>
          </About>
        </Article>
      </Section>

      <Section
        className="playfair font-size-verylarge"
        color="turkis-blue1"
        bgImage={ImageTurkisWay}
        // overlay="full"
        // overlayColor="rgba(38, 159, 178, 0)"
      >
        <Article
          layout="narrow"
          title="Por eso hoy, con mis programas y acompañamiento personalizado"
          titleClassName="tenor white font-size-verylarge lineheight-large"
        >
          <Card>
            <Grid container spacing={1}>
              <Grid item md={6}>
                <BulletItem icon={bulletIcon1}>
                  Te llevo paso a paso para que salgas de la insatisfacción y
                  las incertidumbre y{" "}
                  <span className="bold">
                    {" "}
                    potencies la confianza y la seguridad en ti misma.{" "}
                  </span>
                </BulletItem>
                <BulletItem icon={bulletIcon1}>
                  Te ayudo a construir{" "}
                  <span className="bold"> la estrategia </span> que necesitas
                  para{" "}
                  <span className="bold">
                    descubrir y conseguir lo que TÚ realmente quieres.{" "}
                  </span>
                </BulletItem>
              </Grid>
              <Grid item md={6}>
                <BulletItem icon={bulletIcon1}>
                  Te enseño{" "}
                  <span className="bold"> herramientas para toda la vida </span>{" "}
                  con las que podrás vencer las dudas, el miedo y la inseguridad
                  que aparecerán en tu camino.
                </BulletItem>
                <BulletItem icon={bulletIcon1}>
                  Y también te <span className="bold"> acompaño y apoyo</span>{" "}
                  durante el trayecto hasta que te sientas segura y confiada
                  para lograr todo lo que te propongas.
                </BulletItem>
              </Grid>
            </Grid>
          </Card>
        </Article>
      </Section>

      <Section color="white">
        <br />
        <Article
          layout="wide"
          titleClassName="tenor blue1 font-size-verylarge  lineheight-large"
          //title="Mejor, que te lo digan las que ya han trabajado conmigo"
          //title="Esto es lo que dicen mis clientes"
          title="Ellas ya saben lo que es trabajar conmigo"
        >
          <Carousel dots arrows slidesToShow={2} slidesToScroll={1}>
            <Testimonial name={TestName_10} variation="small">
              {" "}
              {Testimonio_10}
            </Testimonial>
            <Testimonial name={TestName_9} variation="small">
              {" "}
              {Testimonio_9}
            </Testimonial>
            <Testimonial name={TestName_8} variation="small">
              {" "}
              {Testimonio_8}
            </Testimonial>
          </Carousel>
        </Article>
      </Section>

      <Section color="white">
        <Article className="bold center font-size-medium  lineheight-large">
          {/*           Ahora que me conoces un poco más.
          <br />
          <br /> */}
          <span className="bold tenor center font-size-verylarge turkis-blue1 lineheight-large">
            ¿Quieres saber cómo podemos usar el poder de tus emociones y tu
            inteligencia para crear la estrategia que transformará tu vida?
          </span>
        </Article>
      </Section>

      <Section color="beige1">
        <Article
          layout="wide"
          titleClassName="blue1 playfair font-size-verylarge  lineheight-large "
          title="DESCUBRE CÓMO PODEMOS TRABAJAR JUNTAS"
        >
          <Grid container spacing={3} justify="center">
            <Grid item md={4} xs={10}>
              <Card
                link="/proximamente"
                avatar={ImageSesiones}
                titleClassName="tenor font-size-medium"
                className="center"
                title={
                  <span>
                    Power Coaching
                    <br />
                    <strong>Sesiones 1 to 1</strong>
                  </span>
                }
              >
                Nos enfocamos en tus desafíos personales y en como superarlos.
              </Card>
            </Grid>
            <Grid item md={4} xs={10}>
              <Card
                link="/curso-amplifica-tu-confianza"
                avatar={ImageConfianza}
                titleClassName="tenor font-size-medium"
                className="center"
                title={
                  <span>
                    Curso
                    <br />
                    <strong>Amplifica tu Confianza</strong>
                  </span>
                }
              >
                Descubre como, tener confianza en ti misma, puede transformar
                radicalmente tu vida.
              </Card>
            </Grid>
            <Grid item md={4} xs={10}>
              <Card
                link="/proximamente"
                avatar={ImageConfidenceRev}
                titleClassName="tenor font-size-medium"
                title={
                  <span>
                    Programa Premium
                    <br />
                    <strong>The Confidence Revolution</strong>
                  </span>
                } // "Programa Premium Personalizado 'The Confidence Revolution' "
                className="center"
              >
                Experimenta el poder que llevas dentro y úsalo para conquistar
                tus sueños.
              </Card>
            </Grid>
          </Grid>
        </Article>
      </Section>
      {/* 
      <Section color="white">
        <Article className="playfair bold center turkis-blue1 font-size-medium  lineheight-large">
          imagen y frase aqui
        </Article>
      </Section> */}
      <Section color="blue" id="Center-text" small>
        <Article className="white center lineheight-large">
          <span className="font-size-medium">
            Descarga mi Guía{" "}
            <span className="bold">
              "Cómo descubrir tu propósito y conectar con tu confianza"
              <br />
            </span>
            <span>
              {" "}
              y además entérate de los cursos y talleres en los que puedes
              participar.
            </span>
          </span>
          <Newsletter tag="GIVEAWAY" value="RECONECTA" label="Quiero la guía" />
        </Article>
      </Section>
      {/* <Section color="blue" ClassName="white raleway" small>
        <Article className="white center font-size-medium lineheight-large">
          <span className=" playfair font-size-large">
            Descarga mi Guía{" "}
            <span className="bold">
              "Cómo descubrir tu propósito y conectar con tu confianza"
            </span>
          </span>
          <Newsletter tag="GIVEAWAY" value="RECONECTA" label="Quiero la guía" />
        </Article>
      </Section> */}

      <Footer />
    </>
  )
}
