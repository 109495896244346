import React from "react"
import "./list-card.css"
import Card, { CardProps } from "./card"
import { FaCircle } from "react-icons/fa"
import { getColorStyle } from "../utils/color"

type TextElement = string | React.ReactElement

interface Props extends CardProps {
  image?: string
  text: TextElement[]
  listItemIcon?: React.ReactElement
}

const ListCard: React.FC<Props> = ({
  title = "",
  text = [],
  image,
  titleColor = "text-color",
  titleClassName = "",
  listItemIcon = <FaCircle size="10px" />,
  ...rest
}) => (
  <Card className="list-card-container" {...rest}>
    <div className="left-side">
      {title && (
        <h3 className={titleClassName} style={getColorStyle(titleColor)}>
          {title}
        </h3>
      )}
      <ul>
        {text.map((item, index) => (
          <li key={`${index}${item}`}>
            <span>{listItemIcon}</span>
            {item}
          </li>
        ))}
      </ul>
    </div>
    {image && (
      <div className="list-card-image">
        <img src={image} alt={image} />
      </div>
    )}
  </Card>
)

export default ListCard
